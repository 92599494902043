// custom css
// import "typeface-montserrat";
// import "typeface-merriweather";
import "typeface-inter";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// normalize CSS across browsers
import "./src/css/normalize.css";
import "./src/css/bootstrap-grid.min.css"
import "./src/css/global.css"


export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
    if (location.hash && location.hash.includes("#")) {
        return true;
    }
    window.history.scrollRestoration = 'manual';

    const currentPosition = getSavedScrollPosition(location, location.key);
    let timeout = 100;
    if (currentPosition && currentPosition.length > 1 && currentPosition[1]) {
        const y = currentPosition[1];
        if (y > (2 * window.innerHeight)) {
            timeout = 750;
        }
    }
    setTimeout(() => {
        window.scrollTo(...(currentPosition || [0, 0]));
    }, timeout);
    return false;
};
// Add the OptinMonster script
export const onClientEntry = () => {
    if (typeof window !== "undefined") {
        (function (d, u, ac) {
            var s = d.createElement('script');
            s.type = 'text/javascript';
            s.src = 'https://a.omappapi.com/app/js/api.min.js';
            s.async = true;
            s.dataset.user = u;
            s.dataset.account = ac;
            d.getElementsByTagName('head')[0].appendChild(s);
        })(document, 347059, 367014);
    }
};
